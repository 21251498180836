import * as ProgramApi from "api/ProgramApi";
import * as ApplicationStateActions from "actions/ApplicationStateActions";
import { getDefaultErrorHandler } from "actions/base";
import * as ActionTypes from "constants/ActionTypes";

export function loadClinicPrograms(clinicId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ProgramApi.loadClinicPrograms(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_PROGRAMS_LOADED,
                    clinicId,
                    programs: res.body,
                });
                dispatch(ApplicationStateActions.endWorking());
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinic programs")(error);
            });
    }
}

export function loadClinicProgramsForManufacturer(clinicId) {
  return (dispatch) => {
      dispatch(ApplicationStateActions.startWorking());
      ProgramApi.loadClinicProgramsForManufacturer(clinicId)
          .then((res) => {
              dispatch({
                  type: ActionTypes.CLINIC_PROGRAMS_LOADED,
                  clinicId,
                  programs: res.body,
              });
              dispatch(ApplicationStateActions.endWorking());
          })
          .catch((error) => {
              getDefaultErrorHandler(dispatch, "loading clinic programs for manufacturer")(error);
          });
  }
}

export function updateClinicProgramStatus(program) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ProgramApi.updateClinicProgramStatus(program)
            .then((res) => {
                dispatch({
                    type: ActionTypes.UPDATE_CLINIC_PROGRAM,
                    program,
                });
                dispatch(ApplicationStateActions.endWorking());
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "updating clinic program")(error);
            });
    }
}

export function clinicProgramStatusUpdated(program) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_CLINIC_PROGRAM,
            program,
        });
    }
}

export function updateClinicProgramServiceStatus(programService) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ProgramApi.updateClinicProgramServiceStatus(programService)
            .then((res) => {
                dispatch({
                    type: ActionTypes.UPDATE_CLINIC_PROGRAM_SERVICE,
                    programService,
                });
                dispatch(ApplicationStateActions.endWorking());
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "updating clinic program service")(error);
            });
    }
}

export function clinicProgramServiceStatusUpdated(programService) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_CLINIC_PROGRAM_SERVICE,
            programService,
        });
    }
}

export function loadClinicProgramDetails(clinicId, programId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ProgramApi.loadClinicProgramDetails(clinicId, programId)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.CLINIC_PROGRAM_DETAILS_LOADED,
                        program: res.body,
                    });
                }
                dispatch(ApplicationStateActions.endWorking());
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinic program details")(error);
            });
    }
}

export function loadClinicProgramServiceDetails(clinicId, programId, programServiceId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ProgramApi.loadClinicProgramServiceDetails(clinicId, programServiceId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_PROGRAM_SERVICE_DETAILS_LOADED,
                    clinicId,
                    programId,
                    programService: res.body,
                });
                dispatch(ApplicationStateActions.endWorking());
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinic program service details")(error);
            });
    }
}

export function loadClinicProgramServices(clinicId, programId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ProgramApi.loadClinicProgramServices(clinicId, programId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_PROGRAM_SERVICES_LOADED,
                    clinicId,
                    programId,
                    programs: res.body,
                });
                dispatch(ApplicationStateActions.endWorking());
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinic program services")(error);
            });
    }
}

export function loadProgramWalkThrough(walkThroughId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ProgramApi.loadProgramWalkThrough(walkThroughId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PROGRAM_WALK_THROUGH_LOADED,
                    walkThroughId,
                    walkThroughCode: res.body,
                });
                dispatch(ApplicationStateActions.endWorking());
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinic program services")(error);
            });
    }
}
