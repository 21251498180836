import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./EligibleClientSearch.scss";

import get from "lodash/get";
import map from "lodash/map";

import Modal from "components/common/Modal";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab";
import SortableDataTable from "components/common/SortableDataTable";
import SearchEligibleClients from "../SearchEligibleClients";

import * as ClinicApi from "api/ClinicApi";
import {BOEHRINGER_INGELHEIM} from "constants/ProviderIds";
import {handleErrorResponse} from "utils/request";

function EligibleClientSearch({
    clinicId,
}) {
    const [selectedClientData, setSelectedClientData] = useState(null);
    const [isLoadingLastClientInvoice, setIsLoadingLastClientInvoice] = useState(true);
    const [lastInvoiceData, setLastInvoiceData] = useState(null);
    const selectedClientId = get(selectedClientData, "clientId", null);


    useEffect(() => {
        if (!!clinicId && !!selectedClientId) {
            handleLoadLastClientInvoice();
        }
    }, [clinicId, selectedClientId]);

    const handleLoadLastClientInvoice = () => {
        setIsLoadingLastClientInvoice(true);
        ClinicApi.loadLastInvoice(clinicId, BOEHRINGER_INGELHEIM, selectedClientId)
            .then((res) => {
                const invoiceData = get(res, "body[0]");
                setLastInvoiceData(invoiceData);
                setIsLoadingLastClientInvoice(false);
            })
            .catch((error) => {
                setIsLoadingLastClientInvoice(false);
                handleErrorResponse("loading last client invoice", error);
            });
    };

    const renderEligibleOffersBlock = (patientData) => {
        const hasEligibleOffers = patientData.eligibleOffers.length > 0;
        if (hasEligibleOffers) {
            return (
                <div>
                    {map(patientData?.eligibleOffers, eligibleOfferData => (
                        <div>
                            {eligibleOfferData.shortDescription}
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div>
                <div>* Patient is only eligible for National Offers</div>
                <Link
                    className="pointer text-primary margin-left-md"
                    to={urlToCouponLibrary}
                >
                    View Coupon Library
                </Link>
            </div>
        );
    };

    const renderPatientsTable = (row) => {
        return (
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Patient Name</th>
                    <th>Available Offers</th>
                </tr>
                </thead>
                <tbody>
                {row.eligiblePatients.map(patientData => (
                    <tr key={patientData.patientId}>
                        <td>{patientData.patientName}</td>
                        <td>{renderEligibleOffersBlock(patientData)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    const EXPANDED = {
        selector: "patients",
        key: "patients",
        format: (row) => renderPatientsTable(row)
    };

    const COLUMNS = [
        {
            name: "Client Name",
            selector: "clientName",
            key: "clientName",
            format: (row) => (
                <span>{row.petOwnerFirstName} {row.petOwnerLastName}</span>
            ),
            sortValue: (row) => `${row.petOwnerFirstName} ${row.petOwnerLastName}`,
        },
        {
            name: "Client ID",
            selector: "remoteClientDisplayId",
            key: "remoteClientDisplayId",
        },
        {
            name: "Last Client Invoice with BI Product",
            selector: "lastClientInvoiceId",
            key: "lastClientInvoiceId",
            format: () => {
                if (isLoadingLastClientInvoice) {
                    return <span>Loading...</span>;
                }

                const lastInvoiceId = get(lastInvoiceData, "lastInvoiceId");
                const lastExtendedInvoiceNumber = get(lastInvoiceData, "lastExtendedInvoiceNumber");
                if (lastInvoiceId && lastExtendedInvoiceNumber) {
                    return (
                        <OpenInvoicePDFLinkNewTab
                            className="text-primary"
                            invoiceId={lastInvoiceId}
                            extendedInvoiceNumber={lastExtendedInvoiceNumber}
                        />
                    );
                }

                return <span>No Invoices Found</span>;
            },
        }
    ];

  return (
    <div className={classnames(styles.root, "flex flex-none margin-bottom-sm")}>
        <div
            className={styles.tile}
        >
            <div className={"flex align-center"}>
                <div className="flex-none margin-right-md">
                    <h3>Eligible Clients</h3>
                    <div>Search by client name to view their eligibility status</div>
                </div>
                <div className={"flex-1"}>
                    <SearchEligibleClients
                        clinicId={clinicId}
                        onChange={(data, value) => setSelectedClientData(value)}
                        name="client"
                        value={selectedClientId}
                    />
                </div>
            </div>
            <div className={styles.disclaimer}>
                <span>If you utilize Daysmart or Shepherd as your clinic PIMS, please note your clients will need to input their first and last name in the client ID field when logging into the Eligibility Tracker program, after scanning the QR code.</span>
            </div>
        </div>

        {!!selectedClientData && (
            <Modal
                show
                onClose={() => setSelectedClientData(null)}
                modalTitle="Client Information"
                mediumSmall
            >
                <SortableDataTable
                    columns={COLUMNS}
                    expandedData={EXPANDED}
                    expandedDataOpen
                    rawData={[selectedClientData]}
                    striped
                    green
                />
            </Modal>
        )}
    </div>
  );
}


EligibleClientSearch.propTypes = {
    clientId: PropTypes.number,
    urlToCouponLibrary: PropTypes.string,
}

export default EligibleClientSearch;
