import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import classnames from "classnames";
import * as styles from "./EligibilityTrackerLandingPage.scss";
import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as ProgramApi from "api/ProgramApi";
import * as ProgramActions from "actions/ProgramActions";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import Image from "components/common/Image";
import ProgramTile from "components/programs/ProgramTile";
import EligibilityTrackerInstructions from "./EligibilityTrackerInstructions";
import EligibilityTrackerSetup from "./EligibilityTrackerSetup";
import ProgramOptInDetailsModal from "components/clinic/elements/ProgramOptInDetailsModal";
import { CANCELLED, DECLINED, ELIGIBLE, ENROLLED, INELIGIBLE } from "constants/ProgramStatuses";
import * as UserPermissions from "constants/UserPermissions";
import { getClinicInfo } from "utils/ClinicData";
import logger from "utils/logger";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";

function EligibilityTrackerLandingPage(props) {
    const {
        basePath = "",
        canEditProgramEnrollmentClinic = false,
        clinicId,
        clinicPimsInfo,
        clinicProgramServiceStatusUpdated,
        hasEligibilityTracker = false,
        isSetup,
        programServices,
        service,
        urlToCouponLibrary,
    } = props;

    const history = useHistory();
    const params = useParams();
    const { pathname } = useLocation();
    const programId  = 7;
    const serviceId  = 10;
    const nodeId = Number(params.nodeId);
    const isAdmin = clinicId && includes(pathname, "/admin");
    const isProvider = nodeId && includes(pathname, "/provider");
    const linkBase = isAdmin ? `/admin/clinic/${clinicId}` : isProvider ? `/provider/location/${nodeId}/clinic/${clinicId}` : '';

    const [setupComplete, setSetupComplete] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);

    useEffect(() => {
        if (service?.status && service?.status !== ENROLLED) {
            history.push(`${linkBase}/programs/7`);
        }
    }, [service]);

    useEffect(() => {
        logger.info("EligibilityTrackerLandingPage :: effect :: clinicId = ", clinicId);
		props.getClinicProgramServiceDetails(clinicId, programId, serviceId);
		props.getClinicProgramServices(clinicId, programId);
    }, [clinicId]);

    const handleCompleteSetup = async () => {
        const jsonFields = JSON.parse(service.formFieldsJson);
        jsonFields.IsSetup = true;

        const updatedProgramService = {
            signature: service.signature,
            acceptedTerms: true,
            formFieldsJson: JSON.stringify(jsonFields),
            status: "Enroll",
            systemEnrolled: true,
            clinicId, // Required for API to call correct endpoint
            programServiceId: 10, // Required for API to call correct endpoint
        };

        await ProgramApi.updateClinicProgramServiceProperties(updatedProgramService)
            .then((res) => {
                clinicProgramServiceStatusUpdated(updatedProgramService);
                setSetupComplete(true);
            })
            .catch((error) => {
                handleErrorResponse("updating clinic program service", error);
            })
            .finally(() => {
                // logger.info("EligibilityTrackerLandingPage :: handleCompleteSetup :: setup complete?");
            });
    }

    const handleCancelEnrollment = () => {
        setShowCancelModal(true);
    };

    const handleAfterCancelEnrollment = () => {
        toast.success("Eligibility Tracker enrollment canceled.");
    };

    return (
        <ClinicBase
            clinicId={clinicId}
            docTitle="Eligibility Tracker"
            pageTitle={(
                <div className="flex align-center spaced-content">
                    <div>
	                    <Image
                            src={service.programServiceImage}
                            title={service.programServiceName}
                            alt={service.programServiceName}
                            style={{
                                width: "40px",
                            }}
                        />
                    </div>
                    <div>{service.programServiceName}</div>
                </div>
            )}
        >
            <div className={classnames(styles.root, "spaced-content")}>
                <div className={styles.serviceList}>
                    <div className="padding-bottom-md">
                        <Button
                            icon
                            onClick={() => history.push(`${linkBase}/programs/`)}
                        >
                            <i className="fa fa-arrow-left fa-fw" /> Programs
                        </Button>
                    </div>
                    {map(orderBy(programServices, [p => {
                        if (p.status === ELIGIBLE) return 0;
                        else if (p.status === ENROLLED) return 1;
                        else if (p.status === DECLINED) return 2;
                        else if (p.status === CANCELLED) return 3;
						else if (p.status === INELIGIBLE) return 4;
                        else return 5;
                    }, "programServiceName"]), service => (
                        <ProgramTile
                            key={service.programServiceId}
                            canEditProgramEnrollmentClinic={canEditProgramEnrollmentClinic}
                            clinicId={clinicId}
                            // If this is the selected tile then it should not be clickable
                            disabled={service.programServiceId === serviceId}
                            imageStyle={{
	                            display: "flex",
	                            margin: "auto",
								maxWidth: "100%",
	                            width: "auto",
							}}
                            parentProgram={programId}
                            style={{
                                width: "216px",
                                border: "2px solid rgb(120, 179, 58)",
                                borderRadius: "6px",
                            }}
                            tile={{
                                ...service,
                                id: service.programServiceId,
                                image: service.programServiceImage,
                                name: service.programServiceName,
                                startDate: service.programServiceStartDate,
                                endDate: service.programServiceEndDate,
                            }}
                        />
                    ))}
                </div>
                {isSetup || setupComplete ? (
                    <EligibilityTrackerInstructions
                        basePath={basePath}
                        canEditProgramEnrollmentClinic={canEditProgramEnrollmentClinic}
                        clinicId={clinicId}
                        hasEligibilityTracker={hasEligibilityTracker}
                        linkBase={linkBase}
                        service={service}
                        urlToCouponLibrary={urlToCouponLibrary}
                    />
                ) : (
                    <EligibilityTrackerSetup
                        clinicPimsInfo={clinicPimsInfo}
                        onSetupComplete={handleCompleteSetup}
                        onCancelEnrollment={handleCancelEnrollment}
                    />
                )}
            </div>
            <ProgramOptInDetailsModal
                key={`Program_service_${clinicId}_${programId}_${serviceId}`}
                show={showCancelModal}
                clinicId={clinicId}
                program={service}
                programId={serviceId}
                parentProgram={programId}
                linkBase={linkBase}
                onClose={() => setShowCancelModal(false)}
            />
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const programId = 7;
        const serviceId = 10;
        const basePath = ownProps.match.url.replace("/instructions", "/");
        const clinicProgramServiceList = get(state.entities.clinicProgramServiceDetails, clinicId, {});
        const clinicProgramServiceDetails = get(clinicProgramServiceList, programId, {});
        const programs = get(state.entities.clinicProgramServices, clinicId, {});
        const programServices = get(programs, programId, {});
        const userProfile = state.user.userProfile;
        const canEditProgramEnrollmentClinic = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        const service = get(programServices, serviceId, {});
        const extraFields = service?.formFieldsJson ? JSON.parse(service.formFieldsJson) : {};
        const isSetup = extraFields.IsSetup || typeof(extraFields.IsSetup) === "undefined";

        const clinicProgramDetails = get(state.entities.clinicProgramDetails, clinicId, {});
        const programDetails = get(clinicProgramDetails, programId, null);
        const urlToCouponLibrary = ownProps.match.url.replace("/programs/eligibility-tracker/", "/coupons/library");

        return {
            canEditProgramEnrollmentClinic,
            clinicId,
            basePath,
            hasEligibilityTracker: !!filter(state.entities.clinicPrograms?.[clinicId], { programId: programId, status: ENROLLED })?.length || false,
            programDetails,
            programServices,
            serviceDetails: get(clinicProgramServiceDetails, serviceId, null),
	        service,
            isSetup,
            clinicPimsInfo: clinic?.pimsSoftware,
            urlToCouponLibrary,
        }
    },
    (dispatch) => ({
        getClinicProgramServiceDetails: (clinicId, programId, serviceId) => dispatch(ProgramActions.loadClinicProgramServiceDetails(clinicId, programId, serviceId)),
		getClinicProgramServices: (clinicId, programId) => dispatch(ProgramActions.loadClinicProgramServices(clinicId, programId)),
        clinicProgramServiceStatusUpdated: (programService) => dispatch(ProgramActions.clinicProgramServiceStatusUpdated(programService)),
    })
)(EligibilityTrackerLandingPage);
