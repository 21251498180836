import * as ButtonTypes from "constants/ButtonTypes";

// Button CSS classes
export const DEFAULT = "btn-color-default";
export const BLUE = "btn-color-blue";
export const PRIMARY = "btn-color-primary";
export const WARN = "btn-color-warn";
export const DANGER = "btn-color-danger";
export const SUCCESS = "btn-color-success";
export const LIGHT = "btn-color-light";
export const DARK = "btn-color-dark";
export const NOTIFY = "btn-color-notify";
export const GRAY = "btn-color-gray";
export const OUTLINE = "btn-color-outline";
export const CIRCLE_ICON = "btn-color-circle-icon";
export const ELIGIBILITY_TRACKER = "btn-color-eligibility-tracker";
export const ON_GARD_LIGHT = "btn-color-ongard-light";
export const ON_GARD_DARK = "btn-color-ongard-dark";
export const VET_CHECK = "btn-color-vetcheck";
export const VET_CHECK_HOLLOW = "btn-color-vetcheck-hollow";
export const VOUCHER_GREEN = "btn-color-voucher-text";
export const SELECTED = BLUE;

export function getTypeByColorString(color) {
    switch (color) {
        case ButtonTypes.PRIMARY:
        case "primary":
        case "default":
            return PRIMARY;
        case "blue":
            return BLUE;
        case ButtonTypes.WARN:
        case "warn":
        case "warning":
            return WARN;
        case ButtonTypes.SUCCESS:
        case "success":
            return SUCCESS;
        case ButtonTypes.DANGER:
        case "danger":
            return DANGER;
        case ButtonTypes.NOTIFY:
        case "notify":
            return NOTIFY;
        case ButtonTypes.LIGHT:
        case "light":
            return LIGHT;
        case ButtonTypes.DARK:
        case "dark":
            return DARK;
        case ButtonTypes.GRAY:
        case "gray":
            return GRAY;
        case ButtonTypes.OUTLINE:
        case "outline":
            return OUTLINE;
        case ButtonTypes.CIRCLE_ICON:
        case "circleIcon":
            return CIRCLE_ICON;
        case ButtonTypes.ON_GARD_LIGHT:
        case "ongardLight":
            return ON_GARD_LIGHT;
        case ButtonTypes.ON_GARD_DARK:
        case "ongardDark":
            return ON_GARD_DARK;
        case ButtonTypes.VOUCHER_GREEN:
            return VOUCHER_GREEN;
        case "eligibilityTracker":
            return ELIGIBILITY_TRACKER;
        case "vetcheck":
            return VET_CHECK;
        case "vetcheck-hollow":
            return VET_CHECK_HOLLOW;
        default:
            return DEFAULT;
    }
}
