import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { useHistory } from "react-router";
import moment from "moment";
import map from "lodash/map";
import PropTypes from "prop-types";
import * as styles from "./EligibilityTrackerInstructionsPage.scss";

import Button from "components/common/Button";
import Image from "components/common/Image";
import Modal from "components/common/Modal";
import ProgramOptInDetailsModal from "components/clinic/elements/ProgramOptInDetailsModal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import EligibleClientSearch from "components/eligibilityTracker/EligibleClientSearch";

import { getEligibilityTrackerQRCode } from "api/ClinicApi";

import logger from "utils/logger";
import { downloadFile, imageFile } from "utils/request";


import { ET_FAQ_QUESTIONS } from "constants/ProgramConstants"
import get from "lodash/get";

function EligibilityTrackerInstructions({
    canEditProgramEnrollmentClinic,
    clinicId,
    basePath = "",
    hasEligibilityTracker,
    linkBase,
    service,
    urlToCouponLibrary
}) {
    const history = useHistory();
    const programId  = 7;
    const serviceId  = 10;
    const [qrCodeRes, setQRCodeRes] = useState(null);
    const [qrCode, setQRCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showManageEnrollment, setShowManageEnrollment] = useState(false);
    const [showFAQ, setShowFAQ] = useState(false);
    const [selectedClientData, setSelectedClientData] = useState(null);
    const selectedClientId = get(selectedClientData, "clientId", null);

    const faqQuestions = useMemo(() => {
        return map(ET_FAQ_QUESTIONS, (faq, indx) => (
            <div
                className={styles.faqQuestion}
                key={`faq_${indx}`}
            >
                <h3>{faq.q}</h3>
                <div className={styles.faqAnswer}>
                    {faq.a}
                </div>
            </div>
        ))
    }, []);

    const handleLoadEligibilityTrackerQRCode = () => {
        setLoading(true);
        getEligibilityTrackerQRCode(clinicId)
            .then((res) => {
                setQRCodeRes(res);
                const url = imageFile(res, "jpg", true);
                setQRCode(url);
            })
            .catch((err) => {
                logger.error("Error loading Eligibility Tracker QR Code:", err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (hasEligibilityTracker) {
            handleLoadEligibilityTrackerQRCode();
        }
    }, [hasEligibilityTracker]);

    const handleManageEnrollment = () => {
        setShowManageEnrollment(true);
    };

    const handleSearchEligibleClients = () => {
        history.push(`${basePath}/eligible-clients`);
    };

    const handleDownloadQRCode = () => {
        downloadFile(qrCodeRes, "jpeg");
    };

    return (
        <>
            <div className="flex flex-1 flex-column padding-top-md">
                <h2>Personalized Offers</h2>
                {canEditProgramEnrollmentClinic && (
                    <div>
                        <div className="flex flex-none align-center full-width">
                            <div className="flex-none">Enrolled on {moment(service.userActionDate).format("MM/DD/YYYY")} by {service.signature}</div>
                            <div className="flex-none flex flex-centered no-wrap margin-left-sm">
                                <Button
                                    small
                                    onClick={handleManageEnrollment}
                                    type={"default"}
                                >
                                    Manage Enrollment
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="padding-top-md padding-bottom-xlg">
                    Congratulations, you have completed the setup for Personalized Offers!
                    You may start use of your QR code and unique instant rebate code beginning tomorrow morning.
                    If you have any questions please reach out to Greenline support at <a
                    className="text-primary"
                    href="tel:18666089038"
                >+1 (866) 608-9038</a>, email at <a
                    className="text-primary"
                    href="mailto:support@greenlinepet.com"
                >support@greenlinepet.com</a>, or via live chat below!
                </div>
                <EligibleClientSearch
                    urlToCouponLibrary={urlToCouponLibrary}
                    clinicId={clinicId}
                />
                <div className="flex flex-wrap spaced-content spaced-content-vertical">
                    <div
                        className={classnames(styles.tile, "flex margin-bottom-sm spaced-content")}
                        style={{
                            flex: "2 2 0",
                            minWidth: "460px",
                        }}
                    >
                        <div className="flex flex-column flex-1 height-100-perc">
                            <h3 className="flex-none">Clinic QR Code</h3>
                            <div className="flex-none">Download the QR code to put in your clinic!</div>
                            <div className="flex-1 padding-bottom-lg" />
                            <div className="flex-none flex flex-centered no-wrap">
                                <Button
                                    icon
                                    large
                                    wide
                                    onClick={handleDownloadQRCode}
                                    disabled={!hasEligibilityTracker}
                                >
                                    <i className="fa fa-download" /> Download QR Code
                                </Button>
                            </div>
                        </div>
                        {!!qrCode ? (
                            <div className="flex-none">
                                <Image
                                    className={styles.qrCodeImage}
                                    src={qrCode}
                                    alt="Clinic Eligibility Tracker QR code"
                                />
                            </div>
                        ) : (
                            <div className={styles.qrCodePlaceHolder}>
                                <span>GENERATING<br/> QR CODE</span>
                            </div>
                        )}
                    </div>

                    <div
                        className={classnames(styles.tile, "flex margin-bottom-sm spaced-content")}
                        style={{
                            flex: "2 2 0",
                            minWidth: "460px",
                        }}
                    >
                        <div className="flex flex-column flex-1 height-100-perc">
                            <h3 className="flex-none">Clinic Materials</h3>
                            <div className="flex-none">To order in clinic materials, contact your Boehringer Ingelheim  Representative.</div>
                            <div className="flex-1 padding-bottom-lg" />
                            <div className="flex-none flex flex-centered no-wrap spaced-content">
                                <a
                                    className="btn btn-full btn-color-primary btn-icon"
                                    href="https://cdn.greenlinepet.com/images/eligibilitytracker/clinic_downloads/2024_Personalized_Offers_in_Clinic_Materials_US-PET-0118-2024-B_Wall_Cling.pdf"
                                    target="_blank"
                                >
                                    <i className="fa fa-download" /> Preview Wall Cling
                                </a>
                                <a
                                    className="btn btn-full btn-color-primary btn-icon"
                                    href="https://cdn.greenlinepet.com/images/eligibilitytracker/clinic_downloads/2024_Personalized_Offers_in_Clinic_Materials_US-PET-0118-2024-A_Counter_Card.pdf"
                                    target="_blank"
                                >
                                    <i className="fa fa-download" /> Preview Counter Card
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap spaced-content spaced-content-vertical margin-top-sm">
                    <Button
                        onClick={() => setShowFAQ(true)}
                        text
                        large
                        type={"blue"}
                    >
                        Frequently Asked Questions
                    </Button>
                </div>
            </div>
            <ProgramOptInDetailsModal
                key={`Program_service_${clinicId}_${programId}_${serviceId}`}
                clinicId={clinicId}
                onClose={() => setShowManageEnrollment(false)}
                program={service}
                programId={serviceId}
                parentProgram={programId}
                show={showManageEnrollment}
                linkBase={linkBase}
            />
            <Modal
                show={showFAQ}
                modalTitle={"Frequently Asked Questions"}
                medium
                onClose={() => setShowFAQ(false)}
            >
                <div className={styles.faqModal}>
                    <div className={styles.faqWrapper}>
                        <div className={styles.faqContent}>
                            {faqQuestions}
                        </div>
                    </div>
                    <div className={styles.faqButtonRow}>
                        <Button
                            type={"default"}
                            onClick={() => setShowFAQ(false)}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
            <SpinnerTakeover show={loading} />
        </>
    );
}

EligibilityTrackerInstructions.propTypes = {
    basePath: PropTypes.string,
    canEditProgramEnrollmentClinic: PropTypes.bool,
    clinicId: PropTypes.number,
    hasEligibilityTracker: PropTypes.bool,
    linkBase: PropTypes.string,
    service: PropTypes.object,
    urlToCouponLibrary: PropTypes.string,
}

export default EligibilityTrackerInstructions;
