import React, { useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import * as styles from "components/clinic/pages/ClinicPrograms.scss";
import AccessDeniedPage from "components/provider/pages/AccessDeniedPage";
import ProgramTile from "components/programs/ProgramTile";
import ProviderBase from "components/layout/ProviderBase";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as UserPermissions from "constants/UserPermissions";
import * as ProgramActions from "actions/ProgramActions";
import { getClinicInfo } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import {CANCELLED, DECLINED, ELIGIBLE, ENROLLED, INELIGIBLE} from "constants/ProgramStatuses";
import {getNodeId} from "../../../../utils/AdminData";
import getProviderId from "../../../../utils/getProviderId";

function ProviderClinicPrograms(props) {
    useEffect(() => {
        props.getClinicPrograms(props.clinicId);
    }, [props.clinicId]);

    if (!props.canViewClinicPrograms) {
        return <AccessDeniedPage />;
    }

    const renderProgramTiles = () => {
        if (Object.keys(props.programs).length) {
            return map(orderBy(props.programs, [p => {
                if (p.status === ELIGIBLE) return 0;
                else if (p.status === ENROLLED) return 1;
                else if (p.status === DECLINED) return 2;
                else if (p.status === CANCELLED) return 3;
                else if (p.status === INELIGIBLE) return 4;
                else return 5;
            }, "programName"]), program => (
                <ProgramTile
                    key={program.programId}
                    canEditProgramEnrollmentClinic={props.canEditProgramEnrollmentClinic}
                    clinicId={props.clinicId}
                    imageStyle={{
                        display: "flex",
                        margin: "auto",
                        maxWidth: "100%",
                        width: "auto",
                    }}
                    isManufacturer
                    style={{ width: "216px" }}
                    tile={{
                        ...program,
                        id: program.programId,
                        name: program.programName,
                        image: program.programImage,
                        startDate: program.programStartDate,
                        endDate: program.programEndDate,
                    }}
                />
            ));
        }
        return (
            <div className={styles.noPrograms}>
                <span>No Programs to show!</span>
            </div>
        );
    };

    return (
        <ProviderBase
            location={props.location}
            clinicId={props.clinicId}
            docTitle="Programs"
            providerId={props.providerId}
            nodeId={props.nodeId}
        >
            {!props.clinic ? (
                <SpinnerTakeover show />
            ) : (
                <div className={styles.root}>
                    <div className="flex spaced-content">
                        <div>
                            {renderProgramTiles()}
                        </div>
                    </div>
                    {(!keys(props.programs)?.length || !reject(props.programs, { status: INELIGIBLE})?.length) && (
                        <div className={styles.noPrograms}>
                            <h2 className="text-center">No Available Programs At This Time</h2>
                        </div>
                    )}
                </div>
            )}
        </ProviderBase>
    )
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const nodeId = getNodeId(state, ownProps);
        const providerId = getProviderId(params, userProfile);

        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewClinicPrograms = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_CLINICS_PROGRAMS, userProfile);
        const canEditProgramEnrollmentClinic = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        const canViewProgramEnrollmentClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);

        return {
            clinicId,
            clinic,
            programs: get(state.entities.clinicPrograms, clinicId, {}),
            canEditProgramEnrollmentClinic,
            canViewProgramEnrollmentClinic,
            canViewClinicPrograms,
        }
    },
    (dispatch) => ({
        getClinicPrograms: (clinicId) => dispatch(ProgramActions.loadClinicProgramsForManufacturer(clinicId)),
    })
)(ProviderClinicPrograms);
