
import map from "lodash/map";

/// Use getNameForGameType.js to get the display name.
export const GRAND_SLAM = "GrandSlam";
export const GOLDEN_SUMMER = "GoldenSummer";
export const GROWTH = "GrowthDoses";
export const GROWTH_NGP = "GrowthDosesNGP";
export const DEATH_MATCH = "DeathMatch";
export const DEATH_MATCH_NGP = "DeathMatchNGP";
export const LEAF_PARTY = "LeafParty";
export const ON_GARD = "OnGard";
export const PUPPY_BOWL = "PuppyBowl";
export const PUPPY_BOWL_2 = "PuppyBowl2";
export const PUPPY_POOL_PARTY = "PoolParty";
export const SPRING_BALLOON = "SpringBalloon";

export const GAME_TYPES = {
    GRAND_SLAM: GRAND_SLAM,
    GOLDEN_SUMMER: GOLDEN_SUMMER,
    GROWTH: GROWTH,
    GROWTH_NGP: GROWTH_NGP,
    DEATH_MATCH: DEATH_MATCH,
    DEATH_MATCH_NGP: DEATH_MATCH_NGP,
    LEAF_PARTY: LEAF_PARTY,
    ON_GARD: ON_GARD,
    PUPPY_BOWL: PUPPY_BOWL,
    PUPPY_BOWL_2: PUPPY_BOWL_2,
    PUPPY_POOL_PARTY: PUPPY_POOL_PARTY,
    SPRING_BALLOON: SPRING_BALLOON,
};

export const GAME_NAMES_BY_TYPE = {
    [GRAND_SLAM]: "Grand Slam Game",
    [GOLDEN_SUMMER]: "Golden Summer Games",
    [GROWTH]: "Growth (Doses)",
    [GROWTH_NGP]: "Growth (Doses) with NG+",
    [DEATH_MATCH]: "Challenge Match",
    [DEATH_MATCH_NGP]: "Challenge Match with NG+",
    [ON_GARD]: "On Gard",
    [PUPPY_BOWL]: "Puppy Bowl 2023",
    [PUPPY_BOWL_2]: "Pets Bowl 2024",
    [PUPPY_POOL_PARTY]: "Puppy Pool Party 2023",
    [SPRING_BALLOON]: "Spring Balloon",
    [LEAF_PARTY]: "Leaf Party 2023",
}


export const EVENT_NAMES_BY_GAME_ID = {
    // First option left intentionally blank because first EventId = 1
    [GRAND_SLAM]: ["", "1", "2","3", "4", "5", "6", "7", "8", "9"],
    [GOLDEN_SUMMER]: ["", "Track", "Cycling", "Pole Vault", "Swimming"],
}

export function getNameForGameType(gameTypeId) {
    return GAME_NAMES_BY_TYPE[gameTypeId] || gameTypeId;
}

export function getEventNameForGameEventId(gameTypeId, gameEventId) {
    return EVENT_NAMES_BY_GAME_ID[gameTypeId]?.[gameEventId] || `Event ${gameEventId}`;
}

export const GAME_TYPE_OPTIONS = map(GAME_TYPES, t => ({
    name: getNameForGameType(t),
    value: t
}));

export const ANIMATED_GAMES = {
    [GRAND_SLAM]: true,
    [GOLDEN_SUMMER]: true,
    [PUPPY_BOWL]: true,
    [PUPPY_BOWL_2]: true,
    [PUPPY_POOL_PARTY]: true,
    [SPRING_BALLOON]: true,
    [LEAF_PARTY]: true,
};

export const PRINTABLE_GAMES = {
    [GRAND_SLAM]: true,
    [LEAF_PARTY]: true,
    [PUPPY_POOL_PARTY]: true,
    [SPRING_BALLOON]: true,
}

export const MUTLI_CLINIC_GAMES = {
    [GRAND_SLAM]: true,
    [GOLDEN_SUMMER]: true,
    [GROWTH]: false,
    [GROWTH_NGP]: false,
    [DEATH_MATCH]: true,
    [DEATH_MATCH_NGP]: true,
    [ON_GARD]: true,
    [PUPPY_BOWL]: true,
    [PUPPY_BOWL_2]: true,
    [PUPPY_POOL_PARTY]: true,
    [LEAF_PARTY]: true,
    [SPRING_BALLOON]: true,
};

export const GAME_TYPE_CONFIGS = {
    [GRAND_SLAM]: {
        multiClinic: MUTLI_CLINIC_GAMES[GRAND_SLAM],
        isGrowth: false,
        isChallenge: true,
    },
    [GOLDEN_SUMMER]: {
        multiClinic: MUTLI_CLINIC_GAMES[GOLDEN_SUMMER],
        isGrowth: false,
        isChallenge: true,
    },
    [GROWTH]: {
        multiClinic: false,
        isGrowth: true,
        isChallenge: false,
    },
    [GROWTH_NGP]: {
        multiClinic: false,
        isGrowth: true,
        isChallenge: false,
    },
    [DEATH_MATCH]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [DEATH_MATCH_NGP]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [ON_GARD]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [PUPPY_BOWL]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [PUPPY_BOWL_2]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [PUPPY_POOL_PARTY]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [LEAF_PARTY]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
    [SPRING_BALLOON]: {
        multiClinic: true,
        isGrowth: false,
        isChallenge: true,
    },
}

export const GAME_COMPARISON_PERIODS = {
    YOY: "YearOverYear",
    ROLLING: "RollingPeriod",
};

export const GAME_DURATIONS = {
    SEVEN: "7",
    FOURTEEN: "14",
    THIRTY: "30",
    SIXTY: "60",
    NINETY: "90",
};

//TODO: Need to add the category ids of the other product types...
export const GAME_PRODUCT_TYPES = {
    PARASITICIDE: "Parasiticide",
    DENTAL: "Dental",
    NSAID: "NSAID",
    THERAPEUTIC: "Therapeutic"
};
