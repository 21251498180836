import React from "react";
import { DARK_GREEN } from "content/packages/ETSetupWalkthrough/constants";

export default function PageTitle({ title }) {
    return (
        <div
            className="flex-centered flex-none full-width"
            style={{ textAlign:"center", fontSize: "1.1em", color: DARK_GREEN }}
        >
            <h1>{title}</h1>
        </div>
    );
}
