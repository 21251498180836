import React, { useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as styles from "./EligibilityTrackerLandingPage.scss";
import Button from "components/common/Button";
import logger from "utils/logger";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import FakeLiveChat from "components/clinic/elements/FakeLiveChat";
import ClientExperienceContainer from "content/packages/ETSetupWalkthrough/common/ClientExperienceContainer";
import Modal from "components/common/Modal";
import { getPimsType, HELP_TEXT } from "constants/PimsTypes";
import CheckboxInput from "components/common/CheckboxInput";
import PageTitle from "content/packages/ETSetupWalkthrough/common/PageTitle";
import ProductLogos from "content/packages/ETSetupWalkthrough/common/ProductLogos";
import BiFooter from "content/packages/ETSetupWalkthrough/common/BiFooter";
import Image from "../../../common/Image";
import { DANGER } from "constants/ButtonTypes";

function EligibilityTrackerSetup({
    clinicPimsInfo = "",
    onSetupComplete,
    onCancelEnrollment = () => undefined,
}) {

    const [finishingSetup, setFinishingSetup] = useState(false);
    const [showCodeConfirmation, setShowCodeConfirmation] = useState(false);
    const [showInvoiceConfirmation, setShowInvoiceConfirmation] = useState(false);
    const [showInvoiceExample, setShowInvoiceExample] = useState(false);
    const [showSelections, setShowSelections] = useState(true);
    const [showSelfSetup, setShowSelfSetup] = useState(false);

    const [codeConfirmed, setCodeConfirmed] = useState(false);
    const [codeIdCheck, setCodeIdCheck] = useState(false);
    const [codeDescCheck, setCodeDescCheck] = useState(false);
    const [codePriceCheck, setCodePriceCheck] = useState(false);
    const [codeTaxCheck, setCodeTaxCheck] = useState(false);

    const [invoiceConfirmed, setInvoiceConfirmed] = useState(false);
    const [invoiceCodeCheck, setInvoiceCodeCheck] = useState(false);
    const [invoiceOfferCheck, setInvoiceOfferCheck] = useState(false);
    const [invoicePriceCheck, setInvoicePriceCheck] = useState(false);
    const [invoiceRebateCheck, setInvoiceRebateCheck] = useState(false);

    const codeConfirmDisabled = useMemo(() => {
        return !codeIdCheck || !codeDescCheck || !codePriceCheck || !codeTaxCheck;
    }, [codeIdCheck, codeDescCheck, codePriceCheck, codeTaxCheck]);

    const invoiceConfirmDisabled = useMemo(() => {
        return !invoiceCodeCheck || !invoiceOfferCheck || !invoicePriceCheck || !invoiceRebateCheck;
    }, [invoiceCodeCheck, invoiceOfferCheck, invoicePriceCheck, invoiceRebateCheck]);

    const handleStartSetup = () => {
        setShowSelfSetup(true);
        setShowSelections(false);
    }

    const handleStartOver = () => {
        setShowSelfSetup(false);
        setShowSelections(true);
    }

    const handleConfirmInvoice = () => {
        setInvoiceConfirmed(true);
        setShowInvoiceConfirmation(false);
        setFinishingSetup(true);
    }

    const handleConfirmCode = () => {
        setCodeConfirmed(true);
        setShowCodeConfirmation(false);
    }

    const handleExampleInvoiceClick = () => {
        setShowInvoiceExample(true);
    }

    const handleCancelClick = () => {
        onCancelEnrollment();
    };

    useEffect(() => {
        if (invoiceConfirmed && codeConfirmed) {
            // call API then redirect to final page.
            onSetupComplete();
        }
    }, [invoiceConfirmed, codeConfirmed]);

    const getPIMSlanguage = () => {
        const pimsType = getPimsType(clinicPimsInfo);
        const helpText = HELP_TEXT[pimsType];

        if (helpText) {
            const supportLink = helpText.url ? <a href={helpText.url} target="_blank">{helpText.pimsName} support</a> : <span>your PIMS support</span>;
            return <span>{helpText.text} OR Contact {supportLink}</span>;
        }

        return <span>Review your PIMS manual OR contact your PIMS support.</span>
    }

    const renderSetupSelection = () => {
        if (!showSelections) {
            return null;
        }
        return (
            <>
                <h2>Personalized Offers Setup</h2>
                <div className="padding-top-md padding-bottom-xlg">
                    Please select one of the setup processes below to get started
                </div>
                <div className="flex flex-wrap spaced-content spaced-content-vertical">
                    <div
                        className={classnames(styles.tile, "flex margin-bottom-sm spaced-content")}
                        style={{
                            flex: "2 2 0",
                            minWidth: "460px",
                        }}
                    >
                        <div className="flex flex-column flex-1 height-100-perc">
                            <h3 className="flex-none">Self Enrollment/Setup</h3>
                            <div className="flex-none">Step by step instructions to walk you through the setup
                                process.
                            </div>
                            <div className="flex-1 padding-bottom-lg"/>
                            <div className="flex-none flex flex-centered no-wrap">
                                <Button
                                    icon
                                    large
                                    wide
                                    onClick={handleStartSetup}
                                >
                                    <i className="fa fa-gear"/> Begin Setup
                                </Button>
                            </div>
                            <div className="flex-none flex flex-centered no-wrap margin-top-sm">
                                <Button
                                    large
                                    wide
                                    text
                                    type={DANGER}
                                    onClick={handleCancelClick}
                                >
                                    Cancel Enrollment
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div
                        className={classnames(styles.tile, "flex margin-bottom-sm spaced-content")}
                        style={{
                            flex: "2 2 0",
                            minWidth: "460px",
                        }}
                    >
                        <div className="flex flex-column flex-1 height-100-perc">
                            <h3 className="flex-none">Contact Customer Experience</h3>
                            <div className="flex-none">Our representatives would be happy to walk you through the setup
                                process.
                            </div>
                            <div className="flex-none padding-md text-center text-mid-gray text-lg">
                                <span>+1 (866) 608-9038</span><br/>
                                <span>support@greenlinepet.com</span>
                            </div>
                            <div className="flex-1"/>
                            <div className="flex-none flex flex-centered no-wrap">
                                <FakeLiveChat/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderStep = () => {
        if (!codeConfirmed) {
            return (
                <div className={styles.stepsBlock}>
                    <h3>How to create the code:</h3>
                    <p>To add the instant rebate code needed to your PIMS, you will create a new product code in your
                        category of choice.<br />
                        <span className={styles.disclaimer}>If you utilize Daysmart or Shepherd as your clinic PIMS, please note your clients will need to input their first and last name in the client ID field when logging into the Eligibility Tracker program, after scanning the QR code.</span>
                    </p>


                    <div>
                        <div className={styles.largeNumberPoint}>
                            <div className={styles.largeNumber}>1</div>
                            <div className={styles.text}>
                                Update the code ID to be <strong>NGHGB</strong>
                            </div>
                        </div>
                        <div className={styles.largeNumberPoint}>
                            <div className={styles.largeNumber}>2</div>
                            <div className={styles.text}>
                                Update code description to be <strong>Nexgard/Heartgard Brand Offer</strong>
                            </div>
                        </div>
                        <div className={styles.largeNumberPoint}>
                            <div className={styles.largeNumber}>3</div>
                            <div className={styles.text}>
                                Leave the price/charge amount at <strong>0.00</strong> as the price will vary per client utilizing this program.
                            </div>
                        </div>
                        <div className={styles.largeNumberPoint}>
                            <div className={styles.largeNumber}>4</div>
                            <div className={styles.text}>
                                Make sure tax is not being charged to the item
                            </div>
                        </div>
                    </div>
                    <div className="margin-top-sm padding-left-lg margin-bottom-lg">
                        <Button
                            large
                            wide
                            onClick={() => setShowCodeConfirmation(true)}
                        >Confirm Setup</Button>
                    </div>
                </div>
            )
        }

        return (
            <div className={styles.stepsBlock}>
                <div className="flex">
                    <div className="flex-1 flex flex-column justify-center">
                        <div className="flex-none">
                            <h3>Create a test invoice:</h3>
                            <p>To verify the instant rebate code has been properly set up you’ll need to create a test invoice. Click the Example Invoice on the right to enlarge the details.</p>

                            <div>
                                <div className={styles.largeNumberPoint}>
                                    <div className={styles.largeNumber}>1</div>
                                    <div className={styles.text}>
                                        Add Product to the invoice
                                    </div>
                                </div>
                                <div className={styles.largeNumberPoint}>
                                    <div className={styles.largeNumber}>2</div>
                                    <div className={styles.text}>
                                        Add the Nexgard/Heartgard Brand Offer code to the invoice
                                    </div>
                                </div>
                                <div className={styles.largeNumberPoint}>
                                    <div className={styles.largeNumber}>3</div>
                                    <div className={styles.text}>
                                        Change the price from $0.00 to <strong>-$15.00</strong> (or the offer amount shown to you by the client)
                                    </div>
                                </div>
                                <div className={styles.largeNumberPoint}>
                                    <div className={styles.largeNumber}>4</div>
                                    <div className={styles.text}>
                                        Generate the invoice and verify the instant rebate is applied to the invoice total.
                                    </div>
                                </div>
                            </div>
                            <div className="margin-top-sm padding-left-lg margin-bottom-lg">
                                <Button
                                    large
                                    wide
                                    onClick={() => setShowInvoiceConfirmation(true)}
                                >Confirm Invoice</Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.invoiceExample} onClick={handleExampleInvoiceClick}>
                        <img src={"https://cdn.greenlinepet.com/content/examples/eligibility-tracker/NGHGB-negative.jpg"} alt="Example invoice" />
                        <div className={styles.label}>Example Invoice</div>
                    </div>
                </div>
            </div>
        )

    }



    const renderSelfSetup = () => {
        if (!showSelfSetup) {
            return null;
        }
        return (
            <div className="flex flex-column flex-1">
                <PageTitle title={"Personalized Offers Setup"} />
                <ProductLogos />
                {renderStep()}
                <div className={styles.assistanceBlock}>
                    <h4>For assistance with creating the code:</h4>
                    <ul>
                        <li>Reach out to your Hospital administrator or person in charge of creating inventory/product codes for your clinic</li>
                        <li>{getPIMSlanguage()}</li>
                        <li>OR <a onClick={handleStartOver}>Reach out to Greenline</a> for assistance in creating the code</li>
                    </ul>
                </div>
                <div className={"flex-none"}>
                    <BiFooter />
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="flex flex-1 flex-column padding-top-md">
                {renderSetupSelection()}
                {renderSelfSetup()}
            </div>
            <SpinnerTakeover show={finishingSetup} />
            <Modal
                show={showCodeConfirmation}
                modalTitle={"Confirm Product Code Setup"}
                small
                onClose={() => setShowCodeConfirmation(false)}
            >
                <h3>Check each of the boxes verifying your product code is set up correctly then</h3>
                <div className="margin-bottom-sm margin-top-sm">
                    <CheckboxInput
                        onChange={({ name, value}) => setCodeIdCheck(value)}
                        checked={codeIdCheck}
                        solidIcons={codeIdCheck}
                        name={"codeId"}
                        label={<span>Code ID is set to <strong>NGHGB</strong></span>}
                    />
                </div>
                <div className="margin-bottom-sm">
                    <CheckboxInput
                        onChange={({ name, value}) => setCodeDescCheck(value)}
                        checked={codeDescCheck}
                        solidIcons={codeDescCheck}
                        name={"codeDesc"}
                        label={<span>Code Description is set to <strong>Nexgard/Heartgard Brand Offer</strong></span>}
                    />
                </div>
                <div className="margin-bottom-sm">
                    <CheckboxInput
                        onChange={({ name, value}) => setCodePriceCheck(value)}
                        checked={codePriceCheck}
                        solidIcons={codePriceCheck}
                        name={"codePrice"}
                        label={<span>Price/Charge is set to <strong>0.00</strong></span>}
                    />
                </div>
                <div className="margin-bottom-sm">
                    <CheckboxInput
                        onChange={({ name, value}) => setCodeTaxCheck(value)}
                        checked={codeTaxCheck}
                        solidIcons={codeTaxCheck}
                        name={"codeTax"}
                        label={<span>Tax is <strong>not</strong> being added to the item</span>}
                    />
                </div>
                <div className="flex align-center spaced-content-sm">
                    <Button
                        type={"default"}
                        onClick={() => setShowCodeConfirmation(false)}
                    >
                        Review Instructions
                    </Button>
                    <Button
                        disabled={codeConfirmDisabled}
                        onClick={handleConfirmCode}
                    >
                        Yes, I set up the code correctly
                    </Button>
                </div>
            </Modal>
            <Modal
                show={showInvoiceConfirmation}
                modalTitle={"Confirm Invoice Setup"}
                small
                onClose={() => setShowInvoiceConfirmation(false)}
            >
                <h3>Check each of the boxes verifying your invoice is correct</h3>
                <div className="margin-bottom-sm margin-top-sm">
                    <CheckboxInput
                        onChange={({ name, value}) => setInvoiceCodeCheck(value)}
                        checked={invoiceCodeCheck}
                        solidIcons={invoiceCodeCheck}
                        name={"invoiceCode"}
                        label={<span>Add Product to the invoice</span>}
                    />
                </div>
                <div className="margin-bottom-sm">
                    <CheckboxInput
                        onChange={({ name, value}) => setInvoiceOfferCheck(value)}
                        checked={invoiceOfferCheck}
                        solidIcons={invoiceOfferCheck}
                        name={"invoiceOffer"}
                        label={<span>Add the Nexgard/Heartgard Brand Offer code to the invoice</span>}
                    />
                </div>
                <div className="margin-bottom-sm">
                    <CheckboxInput
                        onChange={({ name, value}) => setInvoicePriceCheck(value)}
                        checked={invoicePriceCheck}
                        solidIcons={invoicePriceCheck}
                        name={"invoicePrice"}
                        label={<span>Change the price from $0.00 to -$15.00 (or the offer amount shown to you by the client)</span>}
                    />
                </div>
                <div className="margin-bottom-sm">
                    <CheckboxInput
                        onChange={({ name, value}) => setInvoiceRebateCheck(value)}
                        checked={invoiceRebateCheck}
                        solidIcons={invoiceRebateCheck}
                        name={"invoiceRebate"}
                        label={<span>Instant Rebate was applied to the invoice total </span>}
                    />
                </div>
                <div className="flex align-center spaced-content-sm">
                    <Button
                        type={"default"}
                        onClick={() => setShowInvoiceConfirmation(false)}
                    >
                        Review Instructions
                    </Button>
                    <Button
                        disabled={invoiceConfirmDisabled}
                        onClick={handleConfirmInvoice}
                    >
                        Yes, the invoice is correct
                    </Button>
                </div>
            </Modal>
            <Modal
                show={showInvoiceExample}
                modalTitle={"Example Invoice"}
                medium
                onClose={() => setShowInvoiceExample(false)}
            >
                <div className={styles.exampleModal}>
                    <div className={styles.exampleInvoiceImage}>
                        <Image
                            src={"https://cdn.greenlinepet.com/content/examples/eligibility-tracker/NGHGB-negative.jpg"}
                            alt={"Example Invoice"}

                        />
                    </div>
                    <div className="flex-none flex align-center justify-flex-end">
                        <Button
                            type={"default"}
                            onClick={() => setShowInvoiceExample(false)}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

EligibilityTrackerSetup.propTypes = {
    clinicPimsInfo: PropTypes.string,
    onSetupComplete: PropTypes.func.isRequired,
}

export default EligibilityTrackerSetup;
